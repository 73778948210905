import React from 'react';
import { Link } from 'react-router-dom';
import s from './style.module.scss';
import couple1920 from '../../../assets/images/Couples/couples1920.png';

export const OurСouples = () => {
  return (
    <div className={s.couples}>
      <div className={s.container}>
        <h3>
          <span class={s.left}>НАШИ</span>
          <span class={s.right}>ПАРЫ</span>
        </h3>
        <div className={s.couples_images}>
            <img className={s.couples_image} src={couple1920} alt='couple1' draggable='false' />
        </div>
        <div className={s.couples_action}>
          <Link to='/portfolio'>
            <button>СМОТРЕТЬ ПОРТФОЛИО</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
