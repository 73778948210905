import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import s from './style.module.scss';
import back_dark from '../../assets/images/Icons/back_dark.png';
import FotoPortfolio from '../ui/Foto/FotoPortfolio';
import { BASE_URL } from '../../core/urls';

export const Portfolio = () => {
  const [portfolioItems, setPortfolioItems] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/submitPortfolio/`)
      .then(response => {
        const data = response.data;
        const items = data.map(item => ({
          description: item.description,
          img: item.image,
        }));
        setPortfolioItems(items);
      })
      .catch(error => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, []);

  const handleBackClick = event => {
    event.preventDefault();
    window.history.back();
  };

  return (
    <div className={s.content}>
      <div className={s.main__section}>
        <div className={s.container}>
          <div className={s.portfolio}>
            <div className={s.back}>
              <a href='/' onClick={handleBackClick}>
                <img src={back_dark} alt='go back' draggable='false' />
              </a>
            </div>
            <h2>ПОРТФОЛИО</h2>
            <div className={s.cards}>
              {portfolioItems.map((item, index) => (
                <Link
                  key={index}
                  to={`/portfolio/${item.description
                    .replace(/ & /g, '_&_')
                    .toLowerCase()}`}
                  className={s.card}
                >
                  <FotoPortfolio
                    description={item.description}
                    img={item.img}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
