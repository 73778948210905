import React, { useState, useEffect } from 'react';
import axios from 'axios';
import s from './style.module.scss';
import back_dark from '../../assets/images/Icons/back_dark.png';
import { BASE_URL } from '../../core/urls';

export const VideoFeedback = () => {
  const handleBackClick = event => {
    event.preventDefault();
    window.history.back();
  };

  const [showMore, setShowMore] = useState(false);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/submitVideoFeedback/`);
        setVideos(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке видео:', error);
      }
    };

    fetchVideos();
  }, []);

  const toggleShowMore = () => {
    setShowMore(prevShowMore => !prevShowMore);
  };

  return (
    <div className={s.content}>
      <div className={s.main__section}>
        <div className={s.vfb}>
          <div className={s.container}>
            <div className={s.back}>
              <a href='/' onClick={handleBackClick}>
                <img src={back_dark} alt='go back' draggable='false' />
              </a>
            </div>
            <h2>
              <span className={s.left}>ВИДЕО</span>
              <span className={s.right}>ОТЗЫВЫ</span>
            </h2>

            <div className={s.vfb__main}>
              {videos
                .slice(0, showMore ? videos.length : 6)
                .map((video, index) => (
                  <div className={s.vfb__video} key={index}>
                    {video.video_url && (
                      <div className={s.video_container}>
                        <video
                          controls
                          playsInline
                          poster={video.image}
                          className={s.iframe}
                        >
                          <source src={video.video_url} type='video/mp4' />
                          Ваш браузер не поддерживает видео.
                        </video>
                      </div>
                    )}
                  </div>
                ))}
            </div>

            <div className={s.vfb__action}>
              <button className={s.vfb__toggle} onClick={toggleShowMore}>
                {showMore ? 'СКРЫТЬ' : 'СМОТРЕТЬ ЕЩЕ'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
