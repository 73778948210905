import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import s from './style.module.scss';
import back_light from '../../assets/images/Icons/back_light.png';
import back_dark from '../../assets/images/Icons/back_dark.png';
import { InProgressLoader } from '../ui/InProgressLoader';
import { BASE_URL } from '../../core/urls';

export const Couple = () => {
  const [couple, setCouple] = useState(null);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const { coupleId } = useParams();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/submitPortfolio/`)
      .then(response => {
        const data = response.data;
        setPortfolioItems(data); // Сохраняем данные в состоянии

        const foundCouple = data.find(
          item =>
            item.description.toLowerCase().replace(/ & /g, '_&_') === coupleId,
        );
        if (foundCouple) {
          setCouple(foundCouple);
        }
      })
      .catch(error => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, [coupleId]);

  if (!couple) {
    return (
      <div className={s.InProgressContainer}>
        <div className={s.InProgresText}>Загрузка данных</div>
        <InProgressLoader />
      </div>
    ); // Индикатор загрузки
  }

  // Определяем четность на основе индекса в массиве portfolioItems
  const index = portfolioItems.findIndex(
    item => item.description.toLowerCase().replace(/ & /g, '_&_') === coupleId,
  );
  const isEven = index % 2 === 1;

  return (
    <div className={`${s.content} ${isEven ? s.even : ''}`}>
      <div className={s.main__section}>
        <div className={`${s.container} ${isEven ? s.evenContainer : ''}`}>
          <div className={s.couple}>
            <div className={s.back}>
              <a
                href='/'
                onClick={event => {
                  event.preventDefault();
                  window.history.back();
                }}
              >
                <img
                  src={isEven ? back_dark : back_light}
                  alt='go back'
                  draggable='false'
                />
              </a>
            </div>
            <div className={`${s.text} ${isEven ? s.evenText : ''}`}>
              <div className={s.title}>{couple.description}</div>
            </div>
            <div className={s.about_image}>
              <img
                className={s.original}
                src={couple.image_extra} // Используем поле image_extra
                alt={couple.description}
                draggable='false'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Couple;
