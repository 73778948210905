import React, { useEffect, useRef } from 'react';
import s from './style.module.scss';
import bwmain from '../../../assets/images/BegunovaWedding/bw1920.png';
import round from '../../../assets/images/BegunovaWedding/round.png';
import stroke from '../../../assets/images/BegunovaWedding/stroke.png';

export const BegunovaWedding = () => {
  const introRef = useRef(null);

  useEffect(() => {
    const images = introRef.current.querySelectorAll('img');
    images.forEach(img => {
      img.setAttribute('draggable', 'false');
    });
  }, []);

  return (
    <div className={s.intro} ref={introRef}>
      <div className={s.container}>
        <div className={s.intro_photo}>
          <img className={s.bw_main} src={bwmain} alt='BW_photo' />
          <div className={s.round_container}>
            <a href='/calculator'>
              <div className={s.round}>
                <img className={s.round_img} src={round} alt='round' />
              </div>
              <div className={s.stroke}>
                <img className={s.stroke_img} src={stroke} alt='stroke' />
              </div>
            </a>
          </div>
        </div>
        <h3>
          Организация свадеб без стресса в Москве и МО от 1 000 000₽ под ключ{' '}
        </h3>
      </div>
    </div>
  );
};
