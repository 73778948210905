import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../ui/Button/Button';
import { NavBar } from '../ui/Navbar/navbar';
import { OrderForm } from '../ui/OrderForm/OrderForm';
import logo from '../../assets/images/Header/logo.png';
import s from './styles.module.scss';

export const Header = () => {
  const location = useLocation();
  const [colorScheme, setColorScheme] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    setColorScheme('white');
  }, [location.pathname]);

  return (
    <div className={s.header}>
      <div className={s.headerContent}>
        <a className={s.title} href='/'>
          <img className={s.header__logo} src={logo} alt={'logo'} draggable='false' />
        </a>
        <NavBar currentPath={location.pathname} />
        <div className={s.utilsContainer}>
          <Button
            text={'Связаться'}
            onClick={() => setIsPopupOpen(true)}
            colorScheme={colorScheme}
          />
        </div>
        <OrderForm isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
      </div>
    </div>
  );
};
