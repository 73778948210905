import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import s from './app.module.scss';
import HomePage from './pages/HomePage/HomePage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import AdvantagesPage from './pages/AdvantagesPage/AdvantagesPage';
import ServicesPage from './pages/ServicesPage/ServicesPage';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';
import CouplePage from './pages/CouplePage/CouplePage';
import FeedbackPage from './pages/FeedbackPage/FeedbackPage';
import VideoFeedbackPage from './pages/VideoFeedbackPage/VideoFeedbackPage';
import CalculatorPage from './pages/CalculatorPage/CalculatorPage';
// import BlogPage from './pages/BlogPage/BlogPage';
import CoordinationPage from './pages/CoordinationPage/CoordinationPage';
import InProgressPage from './pages/InProgressPage/InProgressPage';
import PolicyPage from './pages/PolicyPage/PolicyPage';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={s.app}>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutUsPage />} />
        <Route path='/advantages' element={<AdvantagesPage />} />
        <Route path='/services' element={<ServicesPage />} />
        <Route path='/services/:serviceId' element={<CoordinationPage />} />
        <Route path='/portfolio' element={<PortfolioPage />} />
        <Route path='/portfolio/:coupleId' element={<CouplePage />} />
        <Route path='/feedback' element={<FeedbackPage />} />
        <Route path='/videofeedback' element={<VideoFeedbackPage />} />
        <Route path='/calculator' element={<CalculatorPage />} />
        <Route path='/coordination' element={<CoordinationPage />} />
        <Route path='/blog' element={<InProgressPage />} />
        <Route path='/inprogress' element={<InProgressPage />} />
        <Route path='/policy' element={<PolicyPage />} />
      </Routes>
    </div>
  );
};

export default App;
