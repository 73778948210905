import React from 'react';
import cn from 'classnames';
import s from './style.module.scss';
import arrow from '../../../assets/images/Portfolio/arrow.svg';

export const FotoPortfolio = ({ description, img }) => {
  return (
    <div className={cn(s.component, s.shd)}>
      <div className={s.size__fotos}>
        <img src={img} className={s.img} alt='img' />
      </div>
      <div className={cn(s.info, s.info__couplesList)}>
        <div className={s.info__description}>{description}</div>
        <div className={s.info__description}>
          <span className={s.lookText}>{'Смотреть '}</span>
          <img src={arrow} className={s.arrowIcon} alt='arrow' />
        </div>
      </div>
    </div>
  );
};

export default FotoPortfolio;
