import React from 'react';
import { BegunovaWedding } from '../Main/BegunovaWedding';
import { Begunova } from '../Main/Begunova';
import { About } from '../Main/About';
import { OurСouples } from '../Main/OurCouples';
import { InNumbers } from '../Main/InNumbers';
import { OurTeam } from '../Main/OurTeam';
import { Unique } from '../Main/Unique';
import { Preparations } from '../Main/Preparations';
import { InstagramPics } from '../Main/InstagramPics';
import { Guide } from '../Main/Guide';
import { Order } from '../Main/Order';
import s from './style.module.scss';

export const Main = () => {
  return (
    <>
      <main className={s.content}>
        <section className={s.main__section}>
          <BegunovaWedding />
        </section>

        <section className={s.main__section}>
          <Begunova />
        </section>

        <section className={s.main__section}>
          <About />
        </section>

        <section className={s.main__section}>
          <OurСouples />
        </section>

        <section className={s.main__section}>
          <InNumbers />
        </section>

        <section className={s.main__section}>
          <OurTeam />
        </section>

        <section className={s.main__section}>
          <Unique />
        </section>

        <section className={s.main__section}>
          <Preparations />
        </section>

        <section className={s.main__section}>
          <InstagramPics />
        </section>

        <section className={s.main__section}>
          <Guide />
        </section>

        <section className={s.main__section} id='orderSection'>
          <Order />
        </section>
      </main>
    </>
  );
};
