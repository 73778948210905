import React from 'react';
import s from './style.module.scss';
import aglaya from '../../../assets/images/OurTeam/aglaya.png';
import nastya from '../../../assets/images/OurTeam/nastya.png';
import vika from '../../../assets/images/OurTeam/vika.png';
import team_line from '../../../assets/images/OurTeam/team-line.png';
import OUR from '../../../assets/images/OurTeam/OUR.png';
import TEAM from '../../../assets/images/OurTeam/TEAM.png';

export const OurTeam = () => {
  return (
    <div class={s.team}>
      <div class={s.container}>
        <h3>
          <span class={s.left}>НАША</span>
          <span class={s.right}>КОМАНДА</span>
        </h3>
        <div class={s.our_team}>
          <div class={s.team_aglaya}>
            <div class={s.aglaya_image}>
              <img src={aglaya} alt='Aglaya' draggable='false' />
              <img class={s.small} src={OUR} alt='OUR' draggable='false' />
            </div>
            <div class={s.aglaya_info}>
              <div class={s.aglaya_info_name}>АГЛАЯ</div>
              <div class={s.aglaya_info_title}>
                Главный специалист по маркетингу
              </div>
              <img src={team_line} alt='line' />
              <div class={s.aglaya_info_description}>
                В прошлом работала с Марией Цигаль и ее брендом одежды, с
                десертами 0 calories и караоке 22bar (ex Barbara Bar). За год
                благодаря выстроенной стратегии и правильному позиционированию,
                мы добились больших охватов в медиа пространстве, узнаваемости
                на рынке, безупречной репутации агентства и наши цифры растут.
                Это главный показатель. Работать маркетологом в свадебной нише —
                это про визуальное наслаждение. Эстетика свадеб агентства сводит
                меня с ума. Любовь, красота, детали. Идеи рождаются сами собой.
              </div>
            </div>
          </div>
          <div class={s.team_nastya}>
            <div class={s.nastya_image}>
              <img src={nastya} alt='Anastasiya' draggable='false' />
            </div>
            <div class={s.nastya_info}>
              <div class={s.nastya_info_name}>АНАСТАСИЯ</div>
              <div class={s.nastya_info_title}>
                Основатель агентства, свадебный организатор
              </div>
              <img src={team_line} alt='line' draggable='false' />
              <div class={s.nastya_info_description}>
                Все началось в далеком 2016 году. Тогда я сказала: “Я сделаю
                масштабную вечеринку в самом известном клубе Москвы”. Прошло
                пару недель и вот я уже в составе команды организаторов самых
                крупных мероприятий Московского клубного бизнеса. С детства я
                всегда была с лидерскими качествами и понимала, что буду
                работать в сфере организации мероприятий. В июле 2021 года я
                вышла замуж и организовала сама свою свадьбу с 0. В сентябре
                2021 года я открыла свое агентство полного цикла и начала работу
                под личным брендом BEGUNOVA WEDDING. Я знаю все о комфорте,
                стиле, безупречном сервисе, деталях, заботе и любви к парам.
              </div>
            </div>
          </div>
          <div class={s.team_vika}>
            <div class={s.vika_image}>
              <img src={vika} alt='Viktoriya' draggable='false' />
              <img class={s.small} src={TEAM} alt='TEAM' draggable='false' />
            </div>
            <div class={s.vika_info}>
              <div class={s.vika_info_name}>ИРИНА</div>
              <div class={s.vika_info_title}>Старший менеджер проекта</div>
              <img src={team_line} alt='line' draggable='false' />
              <div class={s.vika_info_description}>
                Приехала из небольшого города Вологды. Выпускница медиа класса.
                Красиво фотографирую, поэтому контент для Reels будет обеспечен.
                С детства я была творческим человеком, поэтому после 11-го
                класса знала, что определенно пойду в вуз с такой
                направленностью. Сейчас я учусь в Московском государственном
                институте культуры на направлении менеджмент креативных
                индустрий. Для меня свадьба - это креатив с четкой структурой.
                Миллион мелких деталей которые собираются воедино и создают по
                истине уникальное событие. Я прекрасно сочетаю свои сильные
                навыки менеджмента и креатива с любовью к организации
                мероприятий.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
