import React from 'react';
import s from './style.module.scss';
import video from '../../../assets/images/About/video.png';
import small_wedding from '../../../assets/images/About/small_wedding.png';
import big_wedding from '../../../assets/images/About/big_wedding.png';
import white_suit from '../../../assets/images/About/white_suit.png';
import small_Begunova from '../../../assets/images/About/small_Begunova.png';
import signature from '../../../assets/images/About/signature.png';

export const About = () => {
  return (
    <div className={s.history}>
      <div className={s.container}>
        <div className={s.history_main}>
          <div className={s.history_text}>
            <span>
              <span className={s.Letter}>Н</span>аше агентство полного цикла
              занимается организацией свадеб «под ключ» в Москве и Подмосковье с
              сентября 2021 года.
            </span>
            <div className={s.history_text_all}>
              <p>
                Более 3-х лет я и моя команда создает уникальные свадебные и
                семейные события, на нашем счету более 257 организованных
                концептуальных свадебных мероприятий. От камерных до масштабных.
              </p>
              <p>
                За это время было внедрено много разных бизнес-систем которые
                позволяют экономить нервы и время при подготовке к свадьбе.
              </p>
              <p>
                Наша основная задача - помочь вам определиться в многообразии
                стилей, пожеланий, соединить все в одно целое и сделать
                мероприятие эксклюзивным, реализовать вашу мечту, обеспечить
                спокойствием и комфортом вас как на протяжении подготовки, так и
                в день свадьбы, сэкономить ваше время и оптимизировать бюджет.
              </p>
            </div>
          </div>
          <div className={s.history_video}>
            {/* <img className={s.video} src={video} alt='video' draggable='false' /> */}
            <video className={s.video} alt='main_video' controls playsInline poster={video} draggable="false">
              <source src="/videos/begunova wedding.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img
              className={s.small}
              src={small_wedding}
              alt='wedding'
              draggable='false'
            />
            <img
              className={s.big}
              src={big_wedding}
              alt='big wedding'
              draggable='false'
            />
          </div>
        </div>
        <div className={s.history_photo}>
          <div className={s.history_image}>
            <img
              className={s.original}
              src={white_suit}
              alt='nastya begunova'
              draggable='false'
            />
            <img
              className={s.small}
              src={small_Begunova}
              alt='begunova'
              draggable='false'
            />
          </div>
          <div className={s.history_signature}>
            <img src={signature} alt='signature' draggable='false' />
          </div>
        </div>
      </div>
    </div>
  );
};
