import React, { useEffect, useRef } from 'react';
import s from './style.module.scss';
import order_pic from '../../../assets/images/Order/order.png';
import cross from '../../../assets/images/Forms/close.png';
import { INSTAGRAM_URL } from '../../../core/urls';

export const GuideForm = ({ isOpen, onClose }) => {
  const formRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={s.popup}>
      <div className={s.popupContent} ref={formRef}>
      <div className={s.close} onClick={onClose}>
          <img src={cross} alt="" className={s.cross} />
          {/* <svg
            viewBox='0 0 25 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M19.7916 6.67578L18.3229 5.20703L12.5 11.0299L6.67706 5.20703L5.20831 6.67578L11.0312 12.4987L5.20831 18.3216L6.67706 19.7904L12.5 13.9674L18.3229 19.7904L19.7916 18.3216L13.9687 12.4987L19.7916 6.67578Z'
              fill='#D9D0CD'
            />
          </svg> */}
        </div>
        <div className={s.imageContainer}>
          <img src={order_pic} alt='order' className={s.orderImage} />
          <div className={s.overlay}>
            <h2 className={s.up}>
              Подписывайся на Инстаграм* и оставляй заявку в Директ
            </h2>
            <a href={INSTAGRAM_URL} target='_blank' rel='noopener noreferrer'>
              <button>ПЕРЕЙТИ В ИНСТАГРАМ</button>
            </a>
            <h2 className={s.down}>@begunova_wedding</h2>
            <p>
              *Признана экстремистской организацией и запрещена на территории РФ
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideForm;
