import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import s from './style.module.scss';
import order_pic from '../../../assets/images/Order/order.png';
import cross from '../../../assets/images/Forms/close.png';
import { BASE_URL } from '../../../core/urls';

export const OrderForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    budget: '',
    nick: '',
  });
  const [submitStatus, setSubmitStatus] = useState(null); // null, 'success', 'error'
  const [inputErrors, setInputErrors] = useState({});
  const formRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      setSubmitStatus(null); // Reset the submit status when the form is closed
      setFormData({ name: '', phone: '', budget: '', nick: '' });
      setInputErrors({});
    }
  }, [isOpen]);

  const handleInputChange = e => {
    const { name, value } = e.target;

    if (name === 'phone') {
      // Ограничиваем длину номера телефона 12 символами (включая +7)
      if (value.length > 12) return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFocus = e => {
    const { name } = e.target;
    // Добавляем "+7" при фокусе на поле "phone"
    if (name === 'phone' && !formData.phone.startsWith('+7')) {
      setFormData({
        ...formData,
        phone: '+7',
      });
    }
    // Добавляем "@" при фокусе на поле "nick"
    else if (name === 'nick' && !formData.nick.startsWith('@')) {
      setFormData({
        ...formData,
        nick: '@',
      });
    }

    setInputErrors(prevErrors => ({
      ...prevErrors,
      [name]: undefined, // Убираем ошибку для конкретного поля
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!/^[A-Za-zА-Яа-яЁё\s]+$/.test(formData.name)) {
      errors.name = 'Введите корректное имя';
    }

    if (!/^\+7\d{10}$/.test(formData.phone)) {
      errors.phone = 'Введите корректный номер телефона';
    }

    if (!/^\d+$/.test(formData.budget)) {
      errors.budget = 'Укажите корректный бюджет';
    }

    if (!/^@.{2,}$/.test(formData.nick)) {
      errors.nick = 'Ник должен начинаться с @';
    }

    return errors;
  };

  const isFormFilled = () => {
    return (
      formData.name.trim() !== '' &&
      formData.phone.trim() !== '' &&
      formData.budget.trim() !== '' &&
      formData.nick.trim() !== ''
    );
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setInputErrors(errors);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/submitClient/`, formData);
      console.log('Data submitted successfully:', response.data);
      setSubmitStatus('success');
      setFormData({ name: '', phone: '', budget: '', nick: '' }); // Clear form fields
      setInputErrors({});
    } catch (error) {
      console.error('Error submitting data:', error);
      setSubmitStatus('error');
    }
  };

  const handleClickOutside = useCallback(
    event => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={s.popup}>
      <div className={s.popupContent} ref={formRef}>
        <div className={s.close} onClick={onClose}>
          <img src={cross} alt='' className={s.cross} />
        </div>
        <div className={s.imageContainer}>
          <img src={order_pic} alt='order' className={s.orderImage} />
          <div className={s.overlay}>
            <div className={s.description}>
              <h2>
                Оставьте свои контакты и мы свяжемся с вами в ближайшее время,
                чтобы ответить на все вопросы
              </h2>
              <p>
                Оставляя заявку, вы даете согласие на обработку ваших
                персональных данных в соответствии с{' '}
                <a href='/policy'>политикой обработки персональных данных</a>
              </p>
            </div>
            <form onSubmit={handleSubmit} className={s.form}>
              <div className={s.row}>
                <label>
                  <input
                    type='text'
                    name='name'
                    value={formData.name}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    placeholder='Как к вам обращаться?*'
                    className={inputErrors.name ? s.inputError : ''}
                    required
                  />
                  {inputErrors.name && (
                    <span
                      className={`${s.error} ${s.tooltipError} ${
                        inputErrors.name ? s.visible : ''
                      }`}
                    >
                      {inputErrors.name}
                    </span>
                  )}
                </label>
                <label>
                  <input
                    type='text'
                    name='phone'
                    value={formData.phone}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    placeholder='Ваш номер телефона*'
                    className={inputErrors.phone ? s.inputError : ''}
                    required
                  />
                  {inputErrors.phone && (
                    <span
                      className={`${s.error} ${s.tooltipError} ${
                        inputErrors.phone ? s.visible : ''
                      }`}
                    >
                      {inputErrors.phone}
                    </span>
                  )}
                </label>
              </div>
              <div className={s.row}>
                <label>
                  <input
                    type='text'
                    name='budget'
                    value={formData.budget}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    placeholder='Бюджет свадьбы*'
                    className={inputErrors.budget ? s.inputError : ''}
                    required
                  />
                  {inputErrors.budget && (
                    <span
                      className={`${s.error} ${s.tooltipError} ${
                        inputErrors.budget ? s.visible : ''
                      }`}
                    >
                      {inputErrors.budget}
                    </span>
                  )}
                </label>
                <label>
                  <input
                    type='text'
                    name='nick'
                    value={formData.nick}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    placeholder='Ваш ник в телеграмм*'
                    className={inputErrors.nick ? s.inputError : ''}
                    required
                  />
                  {inputErrors.nick && (
                    <span
                      className={`${s.error} ${s.tooltipError} ${
                        inputErrors.nick ? s.visible : ''
                      }`}
                    >
                      {inputErrors.nick}
                    </span>
                  )}
                </label>
              </div>
              <div className={s.required}>
                * - поле, обязательное для заполнения
              </div>
              {submitStatus === null && (
                <button
                  type='submit'
                  disabled={!isFormFilled()}
                  className={`${s.submitButton} ${
                    isFormFilled() ? s.active : ''
                  }`}
                >
                  ОТПРАВИТЬ
                </button>
              )}
              {submitStatus === 'success' && (
                <h3 className={s.successMessage}>
                  Спасибо! Ваша заявка принята
                </h3>
              )}
              {submitStatus === 'error' && (
                <h3 className={s.errorMessage}>
                  Что-то пошло не так, попробуйте позже
                </h3>
              )}
            </form>
            <div className={s.number}>
              <span>{'+7 916 400 99 80'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
