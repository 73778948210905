import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import s from './style.module.scss';
import { MobMenu } from '../MobileMenu/MobMenu';

export const NavBar = ({ colorLink, line, ...props }) => {

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isPopupOpen && !event.target.closest(`.${s.navBar__burger}`)) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupOpen]);

  return (
    <div className={s.container}>
      <div className={s.navBar__links}>
        <NavLink
          end
          to='/'
          className={({ isActive }) =>
            isActive ? `${s.activeLink}` : s.link
          }
        >
          Главная
        </NavLink>
        <NavLink
          to='/about'
          className={({ isActive }) =>
            isActive ? `${s.activeLink}` : s.link
          }
        >
          О нас
        </NavLink>
        <NavLink
          to='/advantages'
          className={({ isActive }) =>
            isActive ? `${s.activeLink}` : s.link
          }
        >
          Преимущества
        </NavLink>
        <NavLink
          to='/services'
          className={({ isActive }) =>
            isActive ? `${s.activeLink}` : s.link
          }
        >
          Услуги и цены
        </NavLink>
        <NavLink
          to='/portfolio'
          className={({ isActive }) =>
            isActive ? `${s.activeLink}` : s.link
          }
        >
          Портфолио
        </NavLink>
        <NavLink
          to='/feedback'
          className={({ isActive }) =>
            isActive ? `${s.activeLink}` : s.link
          }
        >
          Отзывы
        </NavLink>
        <NavLink
          to='/blog'
          className={({ isActive }) =>
            isActive ? `${s.activeLink}` : s.link
          }
        >
          Блог
        </NavLink>
      </div>
      <div className={s.navBar__burger}>
        <button className={s.navBar__button} onClick={() => setIsPopupOpen(true)}>
            <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.09176 10.418C7.53922 10.418 7.00932 10.6375 6.61862 11.0282C6.22792 11.4189 6.00842 11.9488 6.00842 12.5013C6.00842 13.0538 6.22792 13.5837 6.61862 13.9744C7.00932 14.3651 7.53922 14.5846 8.09176 14.5846H43.5084C44.061 14.5846 44.5909 14.3651 44.9816 13.9744C45.3723 13.5837 45.5918 13.0538 45.5918 12.5013C45.5918 11.9488 45.3723 11.4189 44.9816 11.0282C44.5909 10.6375 44.061 10.418 43.5084 10.418H8.09176ZM6.00842 25.0013C6.00842 24.4488 6.22792 23.9189 6.61862 23.5282C7.00932 23.1375 7.53922 22.918 8.09176 22.918H43.5084C44.061 22.918 44.5909 23.1375 44.9816 23.5282C45.3723 23.9189 45.5918 24.4488 45.5918 25.0013C45.5918 25.5538 45.3723 26.0837 44.9816 26.4744C44.5909 26.8651 44.061 27.0846 43.5084 27.0846H8.09176C7.53922 27.0846 7.00932 26.8651 6.61862 26.4744C6.22792 26.0837 6.00842 25.5538 6.00842 25.0013ZM6.00842 37.5034C6.00842 36.9508 6.22792 36.4209 6.61862 36.0302C7.00932 35.6395 7.53922 35.42 8.09176 35.42H43.5084C44.061 35.42 44.5909 35.6395 44.9816 36.0302C45.3723 36.4209 45.5918 36.9508 45.5918 37.5034C45.5918 38.0559 45.3723 38.5858 44.9816 38.9765C44.5909 39.3672 44.061 39.5867 43.5084 39.5867H8.09176C7.53922 39.5867 7.00932 39.3672 6.61862 38.9765C6.22792 38.5858 6.00842 38.0559 6.00842 37.5034Z" fill="#D2BDB4"/>
            </svg>
        </button>
        <MobMenu isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
        {/* <MobMenu isOpen={true} onClose={() => {}} /> */}
      </div>
    </div>
  );
};

export default NavBar;
