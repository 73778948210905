import React from 'react';
import s from './style.module.scss';
import unique1920 from '../../../assets/images/Unique/allunique1920.png';
// import unique2 from '../../../assets/images/Unique/unique2.png';
// import unique3 from '../../../assets/images/Unique/unique3.png';
// import UNIQUE from '../../../assets/images/Unique/UNIQUE.png';
// import small_uniq_vert from '../../../assets/images/Unique/small-uniq-vert.png';
// import small_uniq_horiz from '../../../assets/images/Unique/small-uniq-horiz.png';

// export const Unique = () => {
//   return (
//     <div className={s.unique}>
//       <div className={s.container}>
//         <h2>
//           <span className={s.left}>КАЖДАЯ ПАРА</span>
//           <span className={s.right}>НЕПОВТОРИМА</span>
//         </h2>
//         <div className={s.unique_info}>
//           <div className={s.unique_info_text}>
//             <span className={s.firstLetterText}>К</span>аждая пара неповторима,
//             поэтому наше внимание всегда сосредоточено на их историях.
//             <div>
//               Наш первостепенный приоритет - выслушать невесту и жениха, изучить
//               их жизненный путь и предложить эксклюзивные идеи.
//             </div>
//           </div>
//           <div className={s.unique_info_images}>
//             <div className={`${s.unique_image} ${s.image1}`}>
//               <img
//                 className={s.image1}
//                 src={unique1}
//                 alt='unique1'
//                 draggable='false'
//               />
//               <img
//                 className={s.small}
//                 src={small_uniq_vert}
//                 alt='unique-small'
//                 draggable='false'
//               />
//               <img
//                 className={s.big}
//                 src={UNIQUE}
//                 alt='UNIQUE'
//                 draggable='false'
//               />
//             </div>
//             <div className={s.unique_image}>
//               <img
//                 className={s.image2}
//                 src={unique2}
//                 alt='unique2'
//                 draggable='false'
//               />
//             </div>
//             <div className={`${s.unique_image} ${s.image3}`}>
//               <img
//                 className={s.image3}
//                 src={unique3}
//                 alt='unique3'
//                 draggable='false'
//               />
//               <img
//                 className={s.small}
//                 src={small_uniq_horiz}
//                 alt='unique-small'
//                 draggable='false'
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export const Unique = () => {
  return (
    <div className={s.unique}>
      <div className={s.container}>
        <div className={s.unique__info}>
            <img className={s.unique__image} src={unique1920} alt='unique content' draggable='false'/>
        </div>
      </div>
    </div>
  );
};
