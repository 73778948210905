import React from 'react';
import s from './style.module.scss';
import wedding from '../../../assets/images/Begunova/main_wedding.png';

export const Begunova = () => {
  return (
    <div class={s.wedding_in_world}>
      <div class={s.container}>
        <div className={s.content}>
          <img className={s.wedding} src={wedding} alt="wedding in world" draggable='false' />
        </div>
      </div>
    </div>
  );
};
