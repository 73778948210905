import React, { useState } from 'react';
import s from './style.module.scss';
import announce_pic from '../../../assets/images/Order/announce.png';
import { OrderForm } from '../../ui/OrderForm/OrderForm';

export const Order = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handlePictureClick = event => {
    event.preventDefault();
    setIsPopupOpen(true);
  };

  return (
    <div className={s.order}>
      <div className={s.container}>
        <div className={s.picture}>
          <a href='/' onClick={handlePictureClick}>
            <img src={announce_pic} alt='announce' draggable='false' />
          </a>
        </div>
      </div>

      <OrderForm isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
    </div>
  );
};

export default Order;
