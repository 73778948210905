import React, { useState } from 'react';
import s from './style.module.scss';
import { GuideForm } from '../../ui/GuideForm/GuideForm';

export const Guide = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = event => {
    event.preventDefault();
    setIsPopupOpen(true);
  };

  return (
    <div className={s.guide}>
      <div className={s.container}>
        <div className={s.guide_text}>
          <span className={s.left}>ПОЛУЧИТЕ БЕСПЛАТНЫЙ ГАЙД</span>
          <span className={s.right}>ПО ВЫБОРУ ПЛОЩАДКИ</span>
        </div>
        <div className={s.guide_action}>
          <button onClick={handleButtonClick}>ПОЛУЧИТЬ ГАЙД</button>
        </div>
      </div>

      <GuideForm isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
    </div>
  );
};
