import React from 'react';
import s from './style.module.scss';
import prep_line from '../../../assets/images/Preparations/prep_line.png';
import prep_logo from '../../../assets/images/Preparations/prep_logo.png';
import prep_map1 from '../../../assets/images/Preparations/prep_map1.png';
import prep_map2 from '../../../assets/images/Preparations/prep_map2.png';

export const Preparations = () => {
  return (
    <div className={s.content}>
      <div className={s.prep_main}>
        <div className={s.container}>
          <div className={s.prep_main_title}>
            <h2>
              <span className={s.left}>ЭТАПЫ</span>
              <span className={s.right}>ПОДГОТОВКИ</span>
            </h2>
          </div>
          <div className={s.prep_info_img}>
            <div className={s.prep_info}>
              <div className={s.prep_logo}>
                <div className={s.logo__line}>
                    <img src={prep_line} alt='line' draggable='false' />
                </div>
                <div className={s.logo__begu}>
                    <img src={prep_logo} alt='logo_begunova' draggable='false' />
                </div>
                <div className={s.logo__line}>
                  <img src={prep_line} alt='line' draggable='false' />
                </div>
                
              </div>
              <div className={s.prep_map1}>
                <img src={prep_map1} alt='map1' draggable='false' />
              </div>
              <div className={s.prep_map2}>
                <img src={prep_map2} alt='map2' draggable='false' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.prep_main_text}>
        <div className={s.container}>
          <div className={s.prep_main_content}>
            <div className={s.prep_content_title}>
              <div className={s.title}>
                Работа после свадьбы не заканчивается!
              </div>
              <div className={s.explain}>
                Мы остаемся на связи даже после свадьбы для того что бы:
              </div>
            </div>
            <div className={s.prep_content_list}>
              <ul>
                <li>
                  Мы проверили все ваши вещи и подарки в случае утери на
                  площадке
                </li>
                <li>Обменялись обратной связью после мероприятия</li>
                <li>
                  Обсудили ваше свадебное путешествие и вы спокойно улетели
                  отдыхать
                </li>
                <li>
                  А пока вы отдыхаете мы подготовили для вас фото и видео
                  материал с вашего дня
                </li>
              </ul>
              <span>
                  Также отдельно по желанию возможны совместные поездки за
                  свадебным платьем и костюмом мечты
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
