import React, { useState, useEffect } from 'react';
import s from './style.module.scss';
import back_light from '../../assets/images/Icons/back_light.png';
import red_curtains from '../../assets/images/AboutUs/red_curtains.png';
import red_curtains_1 from '../../assets/images/AboutUs/red_curtains_1.png';
import red_curtains_2 from '../../assets/images/AboutUs/red_curtains_2.png';
import red_curtains_3 from '../../assets/images/AboutUs/red_curtains_3.png';
import glass from '../../assets/images/AboutUs/glass.png';

export const AboutUs = () => {
  const [imageSrc, setImageSrc] = useState(red_curtains);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setImageSrc(red_curtains_3);
        setIsMobile(true);
      } else if (window.innerWidth <= 990) {
        setImageSrc(red_curtains_2);
        setIsMobile(false);
      } else if (window.innerWidth <= 1720) {
        setImageSrc(red_curtains_1);
        setIsMobile(false);
      } else {
        setImageSrc(red_curtains);
        setIsMobile(false);
      }
    };

    handleResize(); // Проверка при первом рендере
    window.addEventListener('resize', handleResize); // Проверка при изменении размера окна

    return () => {
      window.removeEventListener('resize', handleResize); // Очистка слушателя
    };
  }, []);

  const handleBackClick = event => {
    event.preventDefault();
    window.history.back();
  };

  return (
    <div className={s.content}>
      <div className={s.about_us}>
        <div className={s.container}>
          <div className={s.back}>
            <a href='/' onClick={handleBackClick}>
              <img src={back_light} alt='go back' draggable='false' />
            </a>
          </div>
          <div className={s.section}>
            <div className={s.text_all}>
              <div className={s.text_1}>
                <div className={s.up}>
                  В детстве я всегда отличалась лидерскими качествами и знала,
                  что стану специалистом в области организации мероприятий.
                </div>
                <div className={s.down}>
                  Я всегда что то организовывала в детском саду, школе,
                  университете. Различные встречи, мероприятия, концерты были на
                  мне.
                </div>
              </div>
            </div>
            <div className={s.image}>
              <div className={s.about_image}>
                <img
                  className={s.original}
                  src={imageSrc}
                  alt='nastya begunova'
                  draggable='false'
                />
              </div>
            </div>
          </div>

          <div className={s.section}>
            {!isMobile && (
              <div className={s.image}>
                <img
                  className={s.glass_image}
                  src={glass}
                  alt='glass'
                  draggable='false'
                />
              </div>
            )}
            <div className={s.text_all}>
              <div className={s.text_2}>
                <div className={s.up}>
                  После этого я осознала: организация мероприятий - это
                  сложнейший, кропотливый и увлекательный проект, который
                  требует от нас особой внимательности, аккуратности и
                  мастерства.
                </div>
                {isMobile && (
                  <div className={s.image}>
                    <img
                      className={s.glass_image}
                      src={glass}
                      alt='glass'
                      draggable='false'
                    />
                  </div>
                )}
                <div className={s.down}>
                  В июле 2021 я вышла замуж и организовала свою свадьбу
                  самостоятельно с нуля.<br></br>Это была отправная точка для
                  создания своего свадебного агентства.<br></br>Как вы уже
                  поняли, я решила не задумываясь, что свадьбы - это мое
                  призвание.
                  <br></br>Я открыла свое агентство полного цикла в сентябре
                  2021 года и начала работать под личным брендом BEGUNOVA
                  WEDDING.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
