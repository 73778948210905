import React from 'react';
import s from './style.module.scss';
import stat1 from '../../../assets/images/InNumbers/stat1.png';
import stat2 from '../../../assets/images/InNumbers/stat2.png';
import stat3 from '../../../assets/images/InNumbers/stat3.png';
import stat4 from '../../../assets/images/InNumbers/stat4.png';

export const InNumbers = () => {
  return (
    <div class={s.statistic}>
      <div class={s.container}>
        <h3>В ЦИФРАХ</h3>
        <div class={s.stat__info}>
          <div className={`${s.stat__point} ${s.stat__point1}`}>
            <img src={stat1} alt='stat info 1' draggable='false' />
          </div>
          <div className={`${s.stat__point} ${s.stat__point2}`}>
            <img src={stat2} alt='stat info 2' draggable='false' />
          </div>
          <div className={`${s.stat__point} ${s.stat__point3}`}>
            <img src={stat3} alt='stat info 3' draggable='false' />
          </div>
          <div className={`${s.stat__point} ${s.stat__point4}`}>
            <img src={stat4} alt='stat info 4' draggable='false' />
          </div>
        </div>
      </div>
    </div>
  );
};
