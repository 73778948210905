import React from 'react';
// import cn from 'classnames';
import s from './styles.module.scss';
// import { InProgressLoader } from '../InProgressLoader';
import inwork from '../../../assets/images/InProgress/inwork.png';
import back_dark from '../../../assets/images/Icons/back_dark.png';

// export function InProgress() {
//   return (
//     <div className={s.InProgressContainer}>
//       <div className={s.InProgresText}>
//         Данная страница находится в процессе разработки
//       </div>
// 		<InProgressLoader />
//     </div>
//   );
// }

export const InProgress = () => {

  const handleBackClick = event => {
    event.preventDefault();
    window.history.back();
  };

  return (
    <div className={s.inprogress}>
      <div className={s.container}>
        <div class={s.back}>
          <a href='/' onClick={handleBackClick}>
            <img src={back_dark} alt='go back' draggable='false' />
          </a>
        </div>
        <div className={s.inprogress__main}>
          <div className={s.inprogress__img}>
            <img src={inwork} alt="page in progress" className="inwork" />
          </div>
          <div className={s.inprogress_text}>
            <h5>Данный раздел находится в разработке</h5>
            <p>Извините за неудобства!</p>
            <p>Мы работаем над улучшениями и новыми функциями.</p>
            <p>Спасибо за ваше терпение и понимание!</p>
          </div>
        </div>
      </div>
    </div>
  );
}
