import React from 'react';
import s from './style.module.scss';

export const Button = ({ className, text, onClick, disabled, ...props }) => {
  return (
    <button
      className={`${s.button} ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {text}
    </button>
  );
};

export default Button;
