import React, { useState } from 'react';
import s from './style.module.scss';

export const FotoInstagramPics = ({ img, ...props }) => {
  const [zoomStyle, setZoomStyle] = useState({});

  const handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const offsetX = ((e.clientX - left) / width) * 100;
    const offsetY = ((e.clientY - top) / height) * 100;

    setZoomStyle({
      transformOrigin: `${offsetX}% ${offsetY}%`,
    });
  };

  return (
    <div className={s.component}>
      <div className={s.size__pics} onMouseMove={handleMouseMove}>
        <img
          className={`${s.imgZoom} ${s.img}`}
          src={img}
          alt={'img'}
          style={zoomStyle}
          draggable='false'
        />
      </div>
    </div>
  );
};

export default FotoInstagramPics;
