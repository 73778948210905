import React from 'react';
import { Header } from '../../components/Header';
import { Advantages } from '../../components/Advantages';
import { Footer } from '../../components/Footer';
import s from './style.module.scss';

export default function AdvantagesPage() {
  return (
    <main className={s.content}>
      <div className={s.homepage__header}>
        <Header />
      </div>
      <Advantages />
      <Footer />
    </main>
  );
}
