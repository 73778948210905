import React from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Coordination } from '../../components/Services/Coordination/Coordination';
import { Consultation } from '../../components/Services/Consultation/Consultation';
import { Organization } from '../../components/Services/Organization/Organization';
import { Footer } from '../../components/Footer';
import s from './style.module.scss';

export default function CoordinationPage() {
  const { serviceId } = useParams();

  const renderService = () => {
    switch (serviceId) {
      case '1':
        return <Coordination />;
      case '2':
        return <Organization />;
      case '3':
        return <Consultation />;
      default:
        return <Coordination />;
    }
  };

  return (
    <main className={s.content}>
      <div className={s.homepage__header}>
        <Header />
      </div>
      {renderService()}
      <Footer />
    </main>
  );
}
