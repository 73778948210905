import React, { useEffect, useRef, useState } from 'react';
import FotoInstagramPics from '../../ui/Foto/FotoInstagramPics';
import axios from 'axios';
import s from './style.module.scss';
import { BASE_URL } from '../../../core/urls';

export const InstagramPics = () => {
  const [instagramItems, setInstagramItems] = useState([]);
  const cardsRef = useRef(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/submitInstagram/`)
      .then(response => {
        const data = response.data;
        const items = data.map(item => ({
          img: item.image,
        }));
        setInstagramItems(items);
      })
      .catch(error => {
        console.error('Ошибка при загрузке данных:', error);
      });

    const handleScroll = () => {
      const cards = cardsRef.current.querySelectorAll(`.${s.card}`);

      cards.forEach((card, index) => {
        const topPosition = card.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (topPosition < windowHeight * 0.75) {
          card.classList.add(s.slideInUp); // Добавляем новую анимацию
        } else {
          card.classList.remove(s.slideInUp); // Убираем класс анимации
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={s.container}>
      <div className={s.instagram}>
        <div className={s.text}>
          <div className={s.title}>
            <div className={s.up}>{'НАШ'}</div>
            <div className={s.down}>{'INSTAGRAM*'}</div>
          </div>
          <div className={s.mail}>
            <div className={s.highlight}>{'@begunova_wedding'}</div>
          </div>
          <div className={s.descrcription}>
            {
              '*Признана экстремистской организацией и запрещена на территории РФ'
            }
          </div>
        </div>
        <div ref={cardsRef} className={s.cards}>
          {instagramItems.map((card, index) => (
            <div key={index} className={s.card}>
              <FotoInstagramPics img={card.img} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
